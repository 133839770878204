"use client";

import { type CreateMessage, type Message } from "ai";
import { useChat } from "ai/react";
import React, { useMemo, useState } from "react";

import { type ChatPrompt } from "~/payload-types";
import { typeid } from "~/server/db/typeid";

import ChatBottombar from "./chat-bottombar";
import { ChatMessages } from "./chat-messages";
import ChatTopbar from "./chat-topbar";

export interface Bot {
  name: string;
  seed: string;
}

export interface User {
  name: string;
  picture: string;
}

interface ChatProps {
  user: User;
  existingChatId?: string;
  groupId: number;
  taskInstanceId: string;
  preview?: boolean;
  initialMessages?: (Message | CreateMessage)[];
  prompt: Pick<
    ChatPrompt,
    "id" | "name" | "initialBotMessage" | "studentInstructions" | "topic"
  >;
  subtitle?: string;
}

export function Chat({
  user,
  existingChatId,
  groupId,
  preview,
  prompt,
  taskInstanceId,
  initialMessages,
  subtitle,
}: ChatProps) {
  const [chatId, setChatId] = useState(existingChatId ?? typeid("chat"));
  const _initialMessages = useMemo(() => {
    if (initialMessages) {
      return initialMessages as Message[];
    }
    if (prompt.initialBotMessage) {
      return [
        {
          role: "assistant",
          content: prompt.initialBotMessage,
        },
      ] as Message[];
    }
    return [];
  }, []);

  const chat = useChat({
    initialMessages: _initialMessages,
    body: {
      chatId,
      groupId,
      preview,
      promptId: prompt.id,
      taskInstanceId,
    },
    api: "/api/chat",
  });

  function resetChat() {
    if (_initialMessages.length > 1) {
      const a = document.createElement("a");
      a.href = `/elev/${groupId}/uppgift/${taskInstanceId}`;
      a.click();
      return;
    }
    setChatId(typeid("chat"));
    chat.setMessages(_initialMessages ?? []);
  }

  const bot: Bot = {
    name: prompt.name,
    seed: taskInstanceId,
  };

  return (
    <div className="bg-background flex h-full w-full flex-col justify-between">
      <ChatTopbar bot={bot} subtitle={subtitle ?? prompt.topic} />
      <div className="flex h-full w-full flex-col overflow-x-hidden">
        <ChatMessages
          resetChat={resetChat}
          messages={chat.messages}
          user={user}
          bot={bot}
          studentInstructions={prompt.studentInstructions ?? undefined}
        />
        <ChatBottombar sendMessage={chat.append} isLoading={chat.isLoading} />
      </div>
    </div>
  );
}
