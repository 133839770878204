"use client";

import { InformationCircleIcon } from "@heroicons/react/20/solid";
import base64url from "base64url";
import shuffle from "fisher-yates";
import { EarIcon, XIcon } from "lucide-react";
import { useEffect, useMemo, useRef, useState } from "react";
import { useWizard } from "react-use-wizard";
import { toast } from "sonner";
import { useIsClient, useLocalStorage } from "usehooks-ts";
import { useStore } from "zustand";

import { Icons } from "~/components/icons";
import { PremiumDialogPlaceholder } from "~/components/premium-placeholder";
import { Translation } from "~/components/translations/component";
import { useTranslation } from "~/components/translations/provider";
import { Badge } from "~/components/ui/badge";
import { Button } from "~/components/ui/button";
import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuTrigger,
} from "~/components/ui/context-menu";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogTrigger,
} from "~/components/ui/dialog";
import Twemoji from "~/components/ui/twemoji";
import { VideoEmbed } from "~/components/video-embed";
import { WORD2_AUDIO_LICENSE_TOOL_ID, WORD2_AUDIO_MAX_LENGTH } from "~/config";
import { ResultSchema } from "~/lib/baseTaskSettings";
import { logger } from "~/lib/logger";
import { cn } from "~/lib/utils";
import { saveTaskInstanceResult } from "~/server/actions/tasks";
import { reportWord2Audio } from "~/server/actions/word2Audio";
import { useUserAbility } from "~/server/authorization/useUserAbility";
import { type Voice } from "~/server/zod/word2";

import { QuizStep } from "./QuizStep";
import { SpellStep } from "./SpellStep";
import {
  type LeftRightPair,
  type ViewName,
  type WordItemWithColumnId,
  isImageItem,
  isWordItem,
  useWord2TaskStore,
} from "./provider";
import { RenderWord2Image } from "./render-word2-image";

export function DoneStep({
  groupId,
  taskInstanceId,
}: {
  groupId: number;
  taskInstanceId: string;
}) {
  const [loading, setLoading] = useState(false);
  const Store = useWord2TaskStore();
  const { goToStep } = useWizard();

  const { setView, view, answers, preview, task, setPickedWords } = useStore(
    Store,
    (s) => ({
      setView: s.setView,
      view: s.view,
      answers: s.answers,
      preview: s.preview,
      task: s.computed.task,
      setPickedWords: s.setPickedWords,
    }),
  );

  const genericErrorDescription = useTranslation("common.something-went-wrong");

  async function handleSubmit() {
    logger.debug("handleSubmit");
    if (preview) {
      return;
    }
    setLoading(true);
    const res = ResultSchema.safeParse({
      type: "WORD2",
      view,
      answers: Object.values(answers),
      leftColumnIds: task.leftColumnIds,
      rightColumnIds: task.rightColumnIds,
    });
    if (!res.success) {
      toast.error(genericErrorDescription);
      setLoading(false);
      return;
    }
    await saveTaskInstanceResult({
      summary: res.data,
      taskInstanceId,
      groupId,
    });
    setLoading(false);
  }

  useEffect(() => {
    void handleSubmit();
  }, []);

  const failedWords = useMemo(() => {
    return Object.values(answers)
      .filter(
        (a) =>
          (typeof a.data.errors === "number" && a.data.errors > 0) ||
          (typeof a.data.percentage === "number" && a.data.percentage < 1),
      )
      .map((a) => a.id);
  }, [answers]);

  if (loading) {
    return (
      <div className="text-center">
        <h2 className="py-2 text-2xl font-bold">
          <Translation id="app.task-word2.submitting">
            Skickar in svar...
          </Translation>
        </h2>
      </div>
    );
  }

  function restart() {
    goToStep(0);
    setView(view);
  }

  function restartWithFailedWords() {
    setPickedWords(failedWords);
    goToStep(0);
    setView(view);
  }

  const res = ResultSchema.safeParse({
    type: "WORD2",
    view,
    answers: Object.values(answers),
    leftColumnIds: task.leftColumnIds,
    rightColumnIds: task.rightColumnIds,
  });

  if (!res.success) {
    return (
      <div className="text-center">
        <h2 className="py-2 text-2xl font-bold">
          <Translation id="common.something-went-wrong">
            Något gick fel
          </Translation>
        </h2>
      </div>
    );
  }

  const score = (function () {
    if (res.data.type !== "WORD2") {
      return 0;
    }
    switch (res.data.view) {
      case "QUIZ":
        const onFirstTry = res.data.answers.filter(
          (a) => a.data.errors === 0,
        ).length;
        return (
          <div>
            <p>
              <Translation
                id="app.task-word2.quiz-done"
                replace={{ onFirstTry, total: res.data.answers.length }}
              >
                {"Du klarade {onFirstTry} av {total} ord på första försöket."}
              </Translation>
            </p>
          </div>
        );
      case "SPELL":
        const average =
          res.data.answers.reduce(
            (acc, curr) => acc + Number(curr.data.percentage ?? 0),
            0,
          ) / res.data.answers.length;
        const wasShown = res.data.answers.filter((a) => a.data.wasShown).length;
        return (
          <div>
            <p>
              <Translation id="app.task-word2.spell-done-1">
                Ditt snittresultat:
              </Translation>{" "}
              {Math.min(Math.round((average / wasShown) * 100), 100)}%
            </p>
            <p>
              <Translation id="app.task-word2.spell-done-2">
                Du visade svaren till
              </Translation>{" "}
              {wasShown}{" "}
              <Translation id="app.task-word2.spell-done-3">ord.</Translation>
            </p>
          </div>
        );
      case "EXAM":
        const correct = res.data.answers.filter((a) => a.data.correct).length;
        return (
          <div>
            <p>
              <Translation id="app.task-word2.exam-done-1">
                Du klarade
              </Translation>{" "}
              {correct}{" "}
              <Translation id="app.task-word2.exam-done-2">av</Translation>{" "}
              {res.data.answers.length}{" "}
              <Translation id="app.task-word2.exam-done-3">ord.</Translation>
            </p>
          </div>
        );
      default:
        return null;
    }
  })();

  return (
    <div className="container" data-testid="done-step">
      <div className="text-center">
        <VideoEmbed
          src="/creattie/cats.mp4"
          className="mx-auto mb-2 aspect-square h-72 w-72 max-w-full rounded-full object-cover sm:h-96 sm:w-96"
        />
        {score}
        <div className="space-x-2 pt-4">
          <Button onClick={restart} variant="outline" size="sm">
            <Translation id="app.task-word2.start-over">Börja om</Translation>
          </Button>
          {failedWords.length > 0 && (
            <Button
              onClick={restartWithFailedWords}
              variant="outline"
              size="sm"
            >
              <Translation
                id="app.task-word2.restart-with-failed-words"
                replace={{
                  count: failedWords.length,
                }}
              >
                {"Börja om med orden du hade fel på ({count})"}
              </Translation>
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

export function Step({
  pair,
  viewName,
}: {
  pair: LeftRightPair;
  viewName: ViewName;
}) {
  const Store = useWord2TaskStore();
  const { task, voiceOnly, preview } = useStore(Store, (s) => ({
    task: s.computed.task,
    voiceOnly: s.computed.voiceOnly,
    preview: s.preview,
  }));
  const { activeStep, stepCount } = useWizard();
  const [didListenToWord, setDidListenToWord] = useState(false);

  const imageItems = useMemo(() => pair.left.filter(isImageItem), [pair.left]);
  const wordItems = useMemo(() => {
    const items = pair.left.filter(isWordItem);
    function shuffleAndSlice(items: WordItemWithColumnId[], length: number) {
      return items.map((item) => {
        const word = shuffle(item.word.split("\n")).slice(0, length).join("\n");
        return { ...item, word };
      });
    }
    if (voiceOnly) {
      return shuffleAndSlice(items, 1);
    }
    return shuffleAndSlice(items, 3);
  }, [pair.left, voiceOnly]);

  return (
    <div className="w-full">
      <div className="text-center">
        <Badge variant="secondary">{task.leftTitle}</Badge>
        <div className="mx-auto max-w-3xl whitespace-break-spaces py-2 text-2xl font-bold">
          {imageItems.map((item, index) => (
            <div key={index} className="mx-auto max-w-2xl">
              <RenderWord2Image
                item={item}
                alt="Bild"
                width={600}
                height={400}
                className="mx-auto max-h-[400px] max-w-full object-contain p-2"
              />
            </div>
          ))}
          {wordItems.map((item, index) => (
            <div key={index}>
              {item.word.split("\n").map((word, i) => {
                const column = task.columns.find(
                  (column) => column.id === item.columnId,
                );
                if (!column || column.type !== "word") {
                  return null;
                }
                const hideWord =
                  word.length <= WORD2_AUDIO_MAX_LENGTH &&
                  voiceOnly &&
                  column.voices.length > 0;
                return (
                  <div
                    key={i}
                    className="flex flex-col items-center justify-center gap-1"
                  >
                    <h2
                      className={cn({
                        "font-redacted-script": hideWord,
                      })}
                      data-testid="shown-word"
                    >
                      {hideWord ? "secret" : word}
                    </h2>
                    {column.voices.length > 0 && (
                      <div className="mb-2 flex flex-row flex-wrap justify-center gap-1">
                        {column.voices.map((voice) => {
                          return (
                            <ListenToWord
                              key={voice.voiceId}
                              word={word}
                              voice={voice}
                              onAfterListen={() => setDidListenToWord(true)}
                            />
                          );
                        })}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          ))}
          {didListenToWord && <ReportWordInfo preview={preview} />}
        </div>
      </div>
      <div
        className={cn(
          "mx-auto",
          pair.right[0]?.type === "image" ? "max-w-2xl" : "max-w-sm",
        )}
      >
        {viewName === "QUIZ" && <QuizStep item={pair} key={pair.id} />}
        {viewName === "SPELL" && <SpellStep word={pair} key={pair.id} />}
        <div className="container mt-4 max-w-[400px] text-center">
          <Translation id="app.task-word2.step.counter-1">Ord</Translation>{" "}
          {activeStep + 1}{" "}
          <Translation id="app.task-word2.step.counter-2">av</Translation>{" "}
          {stepCount - 1}
        </div>
      </div>
    </div>
  );
}

interface ListenToWordProps {
  word: string;
  voice: Voice;
  onAfterListen?: () => void;
}

function ListenToWord({ word, voice, onAfterListen }: ListenToWordProps) {
  const audioRef = useRef<HTMLAudioElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const userAbility = useUserAbility();

  const Store = useWord2TaskStore();
  const preview = useStore(Store, (s) => s.preview);

  if (word.length > WORD2_AUDIO_MAX_LENGTH) {
    return null;
  }

  if (!word.trim()) {
    return null;
  }

  if (!preview && !userAbility.tools.includes(WORD2_AUDIO_LICENSE_TOOL_ID)) {
    return (
      <Dialog>
        <DialogTrigger asChild>
          <button type="button">
            <Badge variant="outline">
              <EarIcon className="h-4 w-4" /> {voice.label}
            </Badge>
          </button>
        </DialogTrigger>
        <DialogContent className="sm:max-w-[425px]">
          <PremiumDialogPlaceholder tool={WORD2_AUDIO_LICENSE_TOOL_ID} />
          <DialogFooter>
            <DialogClose asChild>
              <Button type="button" variant="default">
                <Translation id="common.close">Stäng</Translation>
              </Button>
            </DialogClose>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    );
  }

  const handlePlayClick = () => {
    if (audioRef.current) {
      setIsPlaying(true);
      void audioRef.current.play();
    }
  };

  const handleAudioEnded = () => {
    setIsPlaying(false);
    onAfterListen?.();
  };

  const base64 = base64url.encode(
    JSON.stringify({ word, voiceId: voice.voiceId, preview }),
  );

  async function handleReport() {
    await reportWord2Audio({ word, voiceId: voice.voiceId });
    toast.success(
      <Translation id="app.task-word2.report.thanks">
        Tack för din hjälp!
      </Translation>,
    );
  }

  return (
    <ContextMenu>
      <ContextMenuTrigger>
        <div>
          <audio
            ref={audioRef}
            src={`/static/word2-audio/${base64}`}
            onEnded={handleAudioEnded}
            preload="none"
          />
          <button disabled={isPlaying} onClick={handlePlayClick} type="button">
            <Badge variant="outline">
              {!isPlaying ? (
                <EarIcon className="h-4 w-4" />
              ) : (
                <Icons.spinner className="h-4 w-4 animate-spin" />
              )}{" "}
              {voice.label}
            </Badge>
          </button>
        </div>
      </ContextMenuTrigger>
      <ContextMenuContent className="w-48">
        <ContextMenuItem>
          <button
            className="w-100 flex items-center gap-2"
            onClick={handleReport}
            type="button"
          >
            <Twemoji emoji="🚨" className="h-3 w-3" />{" "}
            <Translation id="app.task-word2.report.report">
              Felanmäl ljudet
            </Translation>
          </button>
        </ContextMenuItem>
      </ContextMenuContent>
    </ContextMenu>
  );
}

interface ReportWordInfoProps {
  preview: boolean;
}

function ReportWordInfo({ preview }: ReportWordInfoProps) {
  const [show, setShow] = useLocalStorage(`show-report-word-info`, true, {
    initializeWithValue: false,
  });
  const isClient = useIsClient();
  if (preview) {
    // return null;
  }
  if (!isClient) {
    return null;
  }
  if (!show) {
    return null;
  }
  return (
    <div className="bg-secondary mx-auto max-w-xl rounded-md p-2 px-4 font-normal">
      <div className="flex">
        <div className="flex-shrink-0">
          <InformationCircleIcon
            className="text-foreground h-5 w-5"
            aria-hidden="true"
          />
        </div>
        <div className="ml-3 flex-1 items-center md:flex md:justify-between">
          <p className="text-foreground text-xs">
            <Translation id="app.task-word2.report.info">
              Om ett ljud är dåligt kan du högerklicka på det och välja att
              felanmäla det.
            </Translation>
          </p>
        </div>
        <Button
          onClick={() => setShow(false)}
          variant="ghost"
          size="icon"
          className="-my-2 -mr-2 ml-2"
        >
          <XIcon className="h-4 w-4" />
        </Button>
      </div>
    </div>
  );
}
