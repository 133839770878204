"use client";

import { useEffect, useState } from "react";

import { Chat } from "~/components/chat/chat";
import { Translation } from "~/components/translations/component";
import { type ChatPrompt } from "~/payload-types";
import { getChatPromptById } from "~/server/cms/chat-prompts";
import {
  type AiChatInsertTaskSchema,
  type AiChatSelectSchema,
} from "~/server/zod/tasks";

import { type TaskProps } from "./shared";

type Props = TaskProps<AiChatSelectSchema> | TaskProps<AiChatInsertTaskSchema>;

export const TaskAiChat = ({
  task,
  taskInstance,
  user,
  groupId,
  preview,
}: Props) => {
  const [prompt, setPrompt] = useState<ChatPrompt>();

  useEffect(() => {
    void getChatPromptById(task.promptId).then(setPrompt);
  }, [task.promptId]);

  return (
    <div className="mx-auto flex max-w-7xl flex-col items-center px-2">
      {prompt ? (
        <div className="z-10 mb-4 h-[calc(100vh-200px)] w-full max-w-5xl rounded-lg border text-sm">
          <Chat
            groupId={groupId}
            prompt={prompt}
            taskInstanceId={taskInstance.id}
            user={user}
            preview={preview}
            subtitle={task.name}
          />
        </div>
      ) : (
        <div>
          <Translation id="common.loading">Loading...</Translation>
        </div>
      )}
    </div>
  );
};
