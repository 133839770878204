"use client";

import shuffle from "fisher-yates";
import { AnimatePresence } from "framer-motion";
import { useMemo, useRef } from "react";
import { Wizard as WizardProvider } from "react-use-wizard";
import { useStore } from "zustand";

import AnimatedStep from "~/components/animated-step";
import { BackChevron } from "~/components/back-chevron";
import { Translation } from "~/components/translations/component";

import { ExamView } from "./exam-view";
import { useWord2TaskStore } from "./provider";
import { DoneStep, Step } from "./step";

export const ViewSelected = ({
  groupId,
  taskInstanceId,
}: {
  groupId: number;
  taskInstanceId: string;
}) => {
  const Store = useWord2TaskStore();
  const { view, setView, task, pickedWords } = useStore(Store, (s) => ({
    view: s.view,
    setView: s.setView,
    task: s.computed.task,
    pickedWords: s.pickedWords,
  }));

  const shuffledWords = useMemo(() => {
    let words =
      pickedWords.length > 0
        ? task.pairs.filter((w) => pickedWords.includes(w.id))
        : task.pairs;
    words = shuffle(words);
    return words;
  }, [view, pickedWords]);

  const previousStep = useRef<number>(0);

  if (!view) {
    return null;
  }

  return (
    <>
      <BackChevron
        onClick={() => setView(undefined)}
        text={<Translation id="common.back">Tillbaka</Translation>}
      />
      {view === "EXAM" ? (
        <ExamView groupId={groupId} taskInstanceId={taskInstanceId} />
      ) : (
        <WizardProvider
          wrapper={<AnimatePresence initial={false} mode="wait" />}
        >
          {shuffledWords.map((word) => (
            <AnimatedStep key={word.id} previousStep={previousStep}>
              <Step pair={word} viewName={view} />
            </AnimatedStep>
          ))}
          <DoneStep groupId={groupId} taskInstanceId={taskInstanceId} />
        </WizardProvider>
      )}
    </>
  );
};
