"use client";

import { ChevronRight } from "lucide-react";
import { useState } from "react";
import { toast } from "sonner";
import { type z } from "zod";

import { DashboardHeader } from "~/components/header";
import { Link } from "~/components/link";
import { LoadingButton } from "~/components/loading-button";
import { Translation } from "~/components/translations/component";
import { useTranslation } from "~/components/translations/provider";
import { Button } from "~/components/ui/button";
import { Textarea } from "~/components/ui/textarea";
import { ResultSchema } from "~/lib/baseTaskSettings";
import { logger } from "~/lib/logger";
import { cn } from "~/lib/utils";
import { saveTaskInstanceResult } from "~/server/actions/tasks";
import {
  type IcebreakerTaskInsertSchema,
  type IcebreakerTaskSelectSchema,
} from "~/server/zod/tasks";

import { type TaskProps } from "./shared";

type Props =
  | TaskProps<z.infer<typeof IcebreakerTaskSelectSchema>>
  | TaskProps<z.infer<typeof IcebreakerTaskInsertSchema>>;

export const TaskIceBreaker = ({ task, taskInstance, preview }: Props) => {
  const [done, setDone] = useState(false);
  const [answers, setAnswers] = useState(() => {
    const answers: Record<string, string> = {};
    task.questions.forEach((q) => {
      answers[q.question] = "";
    });
    return answers;
  });
  const [loading, setLoading] = useState(false);

  const genericErrorDescription = useTranslation("common.something-went-wrong");

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (preview) {
      return;
    }
    setLoading(true);
    const res = ResultSchema.safeParse({
      type: task.type,
      questions: task.questions.map((q) => ({
        question: q.question,
        answer: answers[q.question],
      })),
    });
    if (!res.success) {
      toast.error(genericErrorDescription);
      logger.error(res.error);
      setLoading(false);
      return;
    }
    await saveTaskInstanceResult({
      summary: res.data,
      taskInstanceId: taskInstance.id,
      groupId: taskInstance.groupId,
    });
    setLoading(false);
    setDone(true);
  }

  const placeholder = useTranslation(
    "task.student.icebreaker.placeholder",
    "Skriv ditt svar här",
  );

  return (
    <div className="container">
      <form onSubmit={handleSubmit}>
        {!preview && (
          <DashboardHeader heading={task.name}>
            <LoadingButton
              isLoading={loading}
              disabled={done || loading}
              type="submit"
            >
              <Translation id="common.turn-in">Lämna in</Translation>
            </LoadingButton>
          </DashboardHeader>
        )}
        {done ? (
          <IcebreakerPostSubmit
            groupId={taskInstance.groupId}
            preview={preview}
            taskInstanceId={taskInstance.id}
          />
        ) : (
          <div className="bg-background col-span-3 overflow-hidden rounded-xl border p-2 shadow-lg">
            <ul>
              {task.questions.map((q) => (
                <li key={q.question} className="mb-2 mt-4">
                  {q.question}
                  <Textarea
                    name="answer"
                    placeholder={placeholder}
                    rows={3}
                    value={answers[q.question]}
                    required
                    onChange={(e) => {
                      setAnswers((prev) => ({
                        ...prev,
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                        [q.question]: e.target.value,
                      }));
                    }}
                  />
                </li>
              ))}
            </ul>
          </div>
        )}
      </form>
    </div>
  );
};

interface IcebreakerPostSubmitProps {
  groupId: string | number;
  taskInstanceId: string;
  preview?: boolean;
  isSeb?: boolean;
}

export function IcebreakerPostSubmit({
  groupId,
  taskInstanceId,
  preview,
  isSeb,
}: IcebreakerPostSubmitProps) {
  const title = useTranslation(
    "task.student.icebreaker.postsubmit.title",
    "Tack för ditt svar!",
  );
  if (isSeb) {
    return (
      <a href={`https://example.com/seb/quit`}>
        <Button variant="default">
          <Translation id="common.finish">Avsluta</Translation> SEB
        </Button>
      </a>
    );
  }
  return (
    <div className="flex flex-col gap-4">
      <h1 className="font-heading text-center text-2xl md:text-3xl">{title}</h1>
      <p className="text-center">
        <Translation id="task.student.icebreaker.postsubmit.text">
          Du har nu lämnat in svar på icebreakern. Nedanför kan du se dina svar.
        </Translation>
      </p>
      <div className="flex flex-row justify-center gap-2">
        <Link
          href={`/elev/${groupId}/icebreakers/${taskInstanceId}`}
          className={cn({
            "pointer-events-none": preview,
          })}
        >
          <Button disabled={preview} size="sm">
            <Translation id="task.student.icebreaker.postsubmit.show-answers">
              Visa dina svar
            </Translation>
          </Button>
        </Link>
        <Link
          href={`/elev/${groupId}`}
          className={cn({
            "pointer-events-none": preview,
          })}
        >
          <Button variant="outline" disabled={preview} size="sm">
            <Translation id="common.back-to-group">
              Tillbaka till gruppen
            </Translation>
            <ChevronRight className="h-4 w-4" />
          </Button>
        </Link>
      </div>
    </div>
  );
}
